/* eslint-disable prefer-template */
import LogoutIcon from '@mui/icons-material/LoginOutlined'
import ItSupportIcon from '@mui/icons-material/SupportAgentOutlined'

import { ListItem } from '@mui/material'
import {
    NavButton,
    NavGroup,
    NavItem,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { NavBarContentProps, white } from 'components/navbar/types'

export const TertiaryListItems = ({
    open,
    navBarItemClickedHandler
}: NavBarContentProps) => {
    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'row',
                alignItems: 'start',
                flex: 1,
                maxHeight: '220px'
            }}
            className='no-hover'
        >
            <NavGroup>
                <NavItem open={open}>
                    <NavButton
                        onClick={() =>
                            navBarItemClickedHandler('IT_Support', 'IT Support')
                        }
                        title={!open ? 'IT Support' : ''}
                    >
                        <ItSupportIcon
                            style={{
                                color: white,
                                width: 24,
                                height: 21.6
                            }}
                        />
                        <NavItemText open={open}>IT Support</NavItemText>
                    </NavButton>
                </NavItem>
                {/* <NavItem open={open}>
                    <UnstyledRouterLink to='/profile'>
                        <NavButton>
                            <ProfileIcon
                                style={{
                                    color: white,
                                    width: 24,
                                    height: 24
                                }}
                            />
                            <NavItemText open={open}>Profile</NavItemText>
                        </NavButton>
                    </UnstyledRouterLink>
                </NavItem> */}
                <NavItem open={open}>
                    <NavButton
                        onClick={() => navBarItemClickedHandler('RM Unify', 'RM Unify')}
                        title={!open ? 'RM Unify' : ''}
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/images/png/RM_Education_(logo)2.png'
                            }
                            width={24}
                            height={24}
                            alt='RM Education logo'
                            style={{ flex: 0, color: white }}
                        />
                        {open && <NavItemText open={open}>RM Unify</NavItemText>}
                    </NavButton>
                </NavItem>
                <NavItem open={open}>
                    <NavButton
                        onClick={() => navBarItemClickedHandler('Logout', 'Logout')}
                        title={!open ? 'Log out' : ''}
                    >
                        <LogoutIcon
                            style={{
                                color: white,
                                width: 24,
                                height: 24
                            }}
                        />
                        <NavItemText open={open}>Logout</NavItemText>
                    </NavButton>
                </NavItem>
            </NavGroup>
        </ListItem>
    )
}
